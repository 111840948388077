.set-default{
    font-size: 13px;
    padding: 4px 10px;
    border: 1px solid grey;
    border-radius: 13px;
    /* height: 42%; */
    display: block;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    margin-right: 10px;
  }
  .not-active-class{
    font-size: 13px;
    padding: 4px 10px;
    border: 1px solid grey;
    border-radius: 4px;
    height: 42%;
    display: none;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    margin-right: 10px;
  }