.loader-enterprise{
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 6px solid #f9dd2a;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loader-enterprise-container{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffff80;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}