.password-container{
    background-image: url(../icons/password.jpeg);
    background-attachment: cover;
    background-size: cover;
    position: relative;
}
.password-container::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background:linear-gradient(45deg, black, transparent)
}
