.top-fleet-head{
    width: 100%;
    height: 15vh;
    background: #333333 0% 0% no-repeat padding-box;
    padding: 3rem 4rem;
    color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.top-fleet-head > h1{
    font-size: 2.4rem;
    font-weight: bold;
}
.top-fleet-head > p{
    font-size: 1.35rem;
}
.body-fleet{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 85vh;
}
.deals-container{
    width: 80%;
}
.new-deals{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
}
.new-deals > div{
    width: 250px;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    height: 100px;
    background-color: #fff;
    margin: 0 10px;
    border-radius: 14px;
    padding: 1.5rem;
    cursor: pointer;
}
.new-deals > div::hover{
    scale: 1;
}
.pill-top{
    display: flex;
    justify-content: space-between;
}
.below-text{
    font-size: 1.7rem;
    font-weight: bold;
    margin-top: 0.8rem;
}
.new-deals-page{
    padding: 1rem;
}
