.location-search-input{
    font-family: sans-serif;
    margin-top: 10px;
    padding: 8px 15px;
    border-radius: 10px;
    border: transparent;
    background: #e8e8e8;
    width: 100%;
    font-size: 0.9rem;
}
.location-search-input-reg{
    font-family: sans-serif;
    margin-bottom: 10px;
    padding: 8px 15px;
    border-radius: 100px;
    border: transparent;
    background: #e8e8e8;
    width: 100%;
    font-size: .9rem;
}