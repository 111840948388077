.modal-container{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, .4);
    width: 100%;
    height: 100%;
    z-index: 99;
    overflow: auto !important;
}
.css-92nr1f-MuiButtonBase-root-MuiIconButton-root,  .css-12ha4i7, .css-7lip4c, .css-118whkv, .css-2ujp1m,
.css-1s220mh-MuiButtonBase-root-MuiIconButton-root{
    background-color: #fce303 !important;
    color: #000 !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
    border-radius: 15px !important;
    font-size: 0.8rem !important;
    background: #e8e8e8 !important;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 6.5px 14px !important;
}
.css-i4bv87-MuiSvgIcon-root{
    font-size: 0.9rem !important;
}
.css-1flhz3h.Mui-selected{
    color:#000 !important
}
.css-118whkv, .css-2ujp1m{
    border: 16px solid #fce303 !important;
}
.modal{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: #fefefe; */
    margin: auto;
    /* padding: 20px; */
    /* border: 1px solid #888; */
    width: fit-content;
    border-radius: 10px;
    position: fixed;
    height: 0;
    overflow: hidden;
    padding: 0;
}
.modal::-webkit-scrollbar {
    display: none;
  }
.cancel-add-btn{
    width: 751px;
    margin: auto;
    display: flex;
    justify-content: end;
    padding: 16px 0;
}
.cancel-add-btn > button:nth-child(1){
    width: 95px;
    height: 26px;
    border-radius: 10px;
    border: transparent;
    cursor: pointer;
    font-size: 1rem;
    /* box-shadow: 0px 0px 2px 0px; */
}
.cancel-add-btn > button:nth-child(2){
    width: 95px;
    height: 26px;
    border-radius: 10px;
    border: transparent;
    margin-left: 10px;
    background-color: #f9dd07;
    cursor: pointer;
    font-size: 1rem;
}
.no-contacts{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}
.no-contacts > div > i{
    transform: rotate(90deg);
    font-size: 37px;
}
.no-contacts > div{
    margin-top: 20px;
    background: #e8e8e8;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
}
.gate-in > h1{
    font-size: 12px;
    color: #757575;
    margin-bottom: 10px;
}
.gate-in{
    margin-top: 9px;
}
.gigo-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 147px;
}
.twentymins{
    color: #333333;
    font-size: 12px;
    display: flex;
    align-items: center;
    text-align: center;
}
.twentymins input{
    margin: 0;
    margin-right: 9px;
}
.column-other{
    display: flex;
    flex-direction: column;
    justify-content: start;
}
.other-mins > input{
    width: 73%;
    border: transparent;
    background: #e8e8e8;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: grey;
    height: 27px;
    font-size: .8rem !important;
}
.loading-offload-bays > input{
    width: 44%;
    border: transparent;
    background: #e8e8e8;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: grey;
    height: 27px;
    /* text-align: center; */
    font-size: 1rem;
    padding: 0 10px;
}
.add-contacts-wrapper > div:nth-child(1) > h2{
    font-size: 1.3rem;
}
.add-contacts-wrapper > div:nth-child(1) > div:nth-child(12) > div:nth-child(1) > div{
    font-size: 1rem;
}
.css-fhpqww{
    z-index: 9999 !important;
}
.loading-offload-bays{
    margin-top: 10px;
}
.animate-addcontact-one{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: #fefefe; */
    margin: auto;
    /* padding: 20px; */
    /* border: 1px solid #888; */
    width: fit-content;
    border-radius: 10px;
    position: fixed;
    height: auto !important;
    overflow: hidden !important;
}
.ops-validation{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
}
.ops-validation > div{
    width: fit-content;
    height: fit-content;
    background: #fff;
    /* border: 1px solid; */
    border-radius: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px;
    flex-direction: column;
    box-shadow: 0 -1px 5px 0;

}
.ops-validation > div > p{
    font-size: 15px;
}
.ops-validation > div > button{
    background: #ffe22b;
    padding: 3px 8px;
    margin-top: 10px;
    border-radius: 10px;
}