.bidding{
    width: 73%;
    max-width: 73%;
    margin: auto;
    height: 0;
    overflow: hidden;
}
.bidding-page-container{
    background:#fff;
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 5% auto;
    border-radius: 10px;
    margin-top: 1%;
}
.tril-deal-container{
    display: flex;
    justify-content: space-between;
}
/* .booking-card{
    
} */
.bidding-page-container > div:nth-child(2) > div > div > button{
    font-size: 12px;
    margin-right: 10px;
    padding: 7px;
    background: black;
    border-radius: 100%;
    color: red;
    height: 23px;
    width: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.negotiation-block > span{
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}.negotiation-block{
    display:flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0.5rem;
}
.negotiation-block > span >p:nth-child(1){
    width: 15px;
    height: 15px;
    border-radius: 100%;
    margin-right: 10px;
}
.negotiation-block > span >p:nth-child(1){
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.offer-container-last{
    margin-top: 2rem;
}
.bidding-page-container > div:nth-child(2) > div > div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.booking-card p{
    font-size: 1rem;
}
.bidding-page-container > div:nth-child(2) > div > div::-webkit-scrollbar, 
.bidding-page-container > div:nth-child(2)::-webkit-scrollbar,
.bidding-page-container > div:nth-child(1)::-webkit-scrollbar
{
    display: none  !important;
}
.bidding-page-container > div:nth-child(2) > div:nth-child(3) > div:nth-child(1){
    align-items: start !important;
}
.bidding-page-container > div:nth-child(2) > div:nth-child(3) > div:nth-child(1) > p:not(
    .bidding-page-container > div:nth-child(2) > div:nth-child(3) > div:nth-child(1) > p:nth-child(1)
){
    color: grey;
}
.bid-parent{
    position: relative;
    height: fit-content;
    width: 100%;
    margin-top: 11px;
    background: #f5f5f5;
    padding:10px;
    border-radius: 15px;
    display: flex;
}
.btn-settle-block{
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: end;
}
.btn-settle-block > div{
    margin-right: 1.7rem;
    height:31px;
}
.btn-settle-block > div > button{
    padding: 4px;
    font-size: 13px;
    border: 1px solid;
    width: 89px;
    border-radius: 0 15px 15px 0;
    height: 100%;
}
.btn-settle-block > div > input{
    background: #e8e8e8;
    height: 100%;
    border-radius: 15px 0 0 15px;
    padding: 0 17px;
    width: 124px;
    font-size: 13px;
    color: #000;
}
.accept-the-bid{
    padding: 0 10px;
    font-size: 13px;
    background: #fde202;
    height: 31px;
    width: 100px;
    border-radius: 15px;
}
.actual-bid-block{
    margin-left: 1.4rem;
    height: 100%;
    width: 30%;
}
.color-bid{
    background: #fde202;
    position: absolute;
    width: 16px;
    height: 100%;
    left: 0;
    border-radius: 18px 0 0 18px;
    top: 0;
}
.bid-container{
    justify-content: unset !important;
    align-items: unset !important;
    height: 186px;
    max-height: 186px;
    overflow: scroll;
    flex-direction:column;
}
.card-container{
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    padding: 13px 8px;
    border-bottom: 1.4px solid #b1b1b1;
    cursor: pointer;
}
.card-container > div:nth-child(2){
    text-align: end;
}
.card-container > div:nth-child(1) > p:nth-child(1){
    font-size: 1.3rem;
    margin-bottom: 0.8rem;
} 
.card-container > div:nth-child(1) > p:nth-child(2){
    font-size: .8rem;
    color: grey;
}   
.card-container > div:nth-child(2) p{
    font-size: .8rem !important;
}
.bidding-page-container > div:nth-child(2) > p, 
.bidding-page-container > div:nth-child(2) > div:nth-child(3) > div:nth-child(1) p,
.bidding-page-container > div:nth-child(2) > div:nth-child(3) > div:nth-child(2) > p,
.bidding-page-container > div:nth-child(2) > div.offer-container-last > h1{
    font-size: 1rem !important;
}
.offer-container-last > div:nth-child(2) > p, 
.negotiation-block span p,
.offer-container-last > div.bid-container > div{
    font-size: .8rem !important;
}
.card-container > div:nth-child(2) > p{
    font-size: 11.5px;
    line-height: 13px;
    color: grey;
}  
.bidding-page-container > div:first-child{
    background-color: #e8e8e8;
    border-radius: 10px 0 0 10px;
    max-height: 500px;
    min-height: 500px;
    overflow: scroll;
    width: 30%;
    padding: 19px 17px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; 
    overflow: -moz-scrollbars-none
}
.bidding-page-container > div:first-child::-webkit-scrollbar,
.bidding-page-container>div:last-child::-webkit-scrollbar
.bid-container::-webkit-scrollbar{
    display: none !important;
  }
.bidding-page-container > div:first-child > div{
    display: flex;
    justify-content: space-between;
}
.bidding-page-container > div:last-child{
    background-color: #fff;
    border-radius: 0 10px 10px 0;
    max-height: 500px;
    min-height: 500px;
    overflow: scroll;
    overflow: auto;
    scrollbar-width: none;
    width: 70%;
    padding: 19px 17px;
}
.bidding-page-container:first-child
.open-bidding{
    height: auto !important;
    width: 90%;
    max-width: 90%;
    margin: auto;
}
.bidding > div > a >i {
    width: 18px;
    color: black;
    background: #fff;
    padding: 16px;
    border-radius: 100%;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}
.bidding > div > span > p {
    font-weight: bold;
    font-size: 15px;
}
.deals{
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 15px;
    /* border: solid; */
    margin-bottom: 2rem;
}
.deals > div:nth-child(1){
    width: 30%;
    background-color: #E8E8E8;
    border-radius: 10px 0px 0px 10px;
    max-height: 500px;
    min-height: 500px;
    overflow: scroll;

}
.deals > div:nth-child(1)::-webkit-scrollbar,
.trip-deals::-webkit-scrollbar,
.deals::-webkit-scrollbar{
    display: none;
}

.deals > div:nth-child(2){
    width: 70%;
    background-color: #fff;
    border-radius: 0px 10px 10px 0px;
    max-height: 500px;
    min-height: 500px;
    overflow: scroll;
    overflow: auto;
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none;
}

.default-filter{
    padding: 19px 0;
}
.default-filter > div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.default-filter > div:not(.default-filter > div:nth-child(1)){
    border-bottom: 1px solid #9e9e9e;
    padding-bottom: 12px;
    cursor: pointer;
}
.default-filter > div:nth-child(1){
    margin-bottom: 26px;
    padding: 0px 19px 0px 19px;
}
/* .deals-card > div:nth-child(1) {
align-items: stretch;
} */
.default-filter > div > h1{
    font-weight: normal;
}
.default-filter > div > p{
    font-size: 11px;
}
.deals-card{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: center;
    padding: 10px 19px 0px 19px;
}
.deals-card:hover{
    background-color: #cacaca;
}
.deals-card > div:nth-child(1){
    width: 70%;
}
.deals-card > div:nth-child(1) > h2{
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 7px;
}
.deals-card > div:nth-child(1) > p{
    font-size: 11px;
    color: #606060;
}
.deals-card > div:nth-child(2){
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    text-align: end;
}
.deals-card > div:nth-child(2) > p{
    font-size: 11px;
    color: #606060;
}
.trip-deals{
    padding: 19px;
}
.trip-deals > div:nth-child(1){
    display: flex;
    justify-content: space-between;
}
.trip-deals > div > div:nth-child(2){
    display: flex;
}
.trip-deals > div > div{
    align-items: center;
}
.cancel-ex{
    margin-right: 10px;
    border-radius: 100%;
    padding: 10px;
    background-color: black;
    color: red;
    width: 6px;
    height: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
}
.trip-deals > div > div:nth-child(2){
    cursor: pointer;
}
.trip-deals > div > div:nth-child(2) > p{
    color: red;
}
.trip-deals > div:nth-child(1) > div:nth-child(1) > h2{
    font-weight: normal;
    font-size: 16px;
}
.trip-deals > div:nth-child(1) > div:nth-child(1) > p{
    color: #606060;
    margin-top: 6px;
}
.asking-rate{
    margin-top: 23px;
    padding: 22px 10px;
    border-radius: 18px;
    box-shadow: 0px 4px 15px -1px #a7afb36e;
}
.asking-rate > div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.offer-title{
    margin-top: 38px;
}
.offer-title > h2{
    font-weight: 500;
}
.offer-title > p{
    font-size: 12px;
}
.offer-indication{
    display: flex;
    margin: 12px 0px;
    justify-content: space-between;
}
.offer-indication > div > div > div{
    width: 3px;
    height: 3px;
    padding: 7px;
    border-radius: 100%;
    margin-right: 10px;
}
.offer-indication > div > div > p{
    font-size: 11px;
    margin-right: 15px;
}
.offer-indication > div > div{
    display: flex;
}
.actual-offers{
    padding: 10px;
    background: #F5F5F5;
    border-radius: 15px;
    padding-left: 35px;
    position: relative;
    margin-bottom: 10px;
    cursor: unset !important;
    display: block !important;
    align-items: unset !important;
}
/* .actual-offers>div{
    width:20%;
} */
/* .actual-offers::before{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 16px;
    height: 100%;
    background: #FFE200;
    border-radius: 15px 0px 0px 15px;
} */
.counter::before{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 16px;
    height: 100%;
    background: #213164;
    border-radius: 15px 0px 0px 15px;
}
.confirmed::before{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 16px;
    height: 100%;
    background: #059E00;
    border-radius: 15px 0px 0px 15px;
}
.loads-offered{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.loads-offered p{
    font-size: 11px;
}
/* .loads-offered > div:nth-child(2){
    margin-left: 123px;
} */
.loads-offered > div:nth-child(2) > div > input{
    padding: 4px 15px;
    width: 88px;
    background: #E8E8E8;
    border: transparent;
    border-radius: 15px 0px 0px 15px;
    font-size: 12px;
    height: fit-content;
    color: #000;
}
/* .loads-offered > div:nth-child(2) > div > button{
    padding: 3px 10px;
    font-size: 12px;
    width: 68px;
    border-radius: 0px 15px 15px 0px;
    border: 1px solid #707070;
    cursor: pointer;
} */
.settle-bt{
    padding: 3px 10px;
    font-size: 12px;
    width: 68px;
    border-radius: 0px 15px 15px 0px;
    border: 1px solid #707070;
    cursor: pointer;
    margin-right: 1rem;
}
.accept-offer{
    padding: 4px 15px;
    width: 95px;
    border-radius: 15px;
    border: transparent;
    background: #FFE200;
    cursor: pointer;
    font-size: 12px;
}
.accept-deactivated{
    padding: 4px 15px;
    width: 95px;
    border-radius: 15px;
    border: transparent;
    background: #FFE200;
    cursor: pointer;
    cursor: not-allowed;
    pointer-events: none;
    opacity: .5;
}
.decline-offer{
    padding: 4px 15px;
    width: 87px;
    border: 1px solid;
    border-radius: 15px;
    background: #F5F5F5;
    cursor: pointer;
    font-size: 12px;
}
.settle-inp{
    background: #e8e8e8;
    border: transparent;
    border-radius: 15px 0 0 15px;
    color: #000;
    font-size: 12px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 4px 15px;
    width: 88px;
}
/* .trip-deals > div:nth-child(5) > div > div > div:nth-child(1){
    width: 163px;
} */
.fleets-offers{
    display: flex;
    flex-direction: column;
    align-items: unset !important;
    justify-content: center;
    width: 100%;
}
.yellowBidding{
    left: 0;
    width: 13px;
    background: #ffe201;
    height: 100%;
    position: absolute;
    top: 0;
    border-radius: 10px 0px 0px 10px;
}
.blueBidding{
    left: 0;
    width: 13px;
    background: rgb(33, 49, 100);
    height: 100%;
    position: absolute;
    top: 0;
    border-radius: 10px 0px 0px 10px;
}
.backGreen{
    left: 0;
    width: 13px;
    background: #059E00;
    height: 100%;
    position: absolute;
    top: 0;
    border-radius: 10px 0px 0px 10px;
}
.fa-chevron-left{
    align-items: center;
    background: #fff;
    border-radius: 100%;
    color: #000;
    display: flex;
    height: 26px;
    justify-content: center;
    /* margin-right: 10px; */
    padding: 10px;
    width: 26px;
}