.spinner-container{
    display: flex;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 50%;
    top: 50%;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -50%);
}
.spinner{
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: transparent;
    position: relative;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* .spinner::before{
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    border: 5px solid grey;
    border-radius: 100%;
} */
.spinner::after{
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    border: 5px solid grey;
    border-top: 5px solid #ffe201; 
    border-radius: 100%;
    animation: spinner-anime .5s infinite linear;

}
@keyframes spinner-anime {
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(360deg);
    }
}
