.forgot-container{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 999;
    background-color: #fff;
    width: 100%;
    height: 100%;
}
.forgot{
    width: 339px;
    height: 500px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.forgot > input{
    width: 100%;
    max-width: 100%;
    padding: 13px 15px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: none;
    outline: none;
    background: #f1f1f1;
    font-size: 14px;
}