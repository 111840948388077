.activedeals{
    width: 100%;
}
/* .notActiveDeals:hover{
    width: 100%;
}
.notActiveDeals{
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    width: 91.666667%;
    padding: 2rem;
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    border-radius: 2rem;
    padding-left: 6rem;
    background:"#fff"
} */
.calender{
    width: 100%;
    background: #F2F2F2;
    border: none;
}
.highlight {
    color:red;
 }
