.contacts-details{
    margin-top: 14px;
    display: flex;
    height: 51px;
    align-items: center;
    border-radius: 10px;
    justify-content: space-between;
    overflow: scroll;
    /* flex-direction: column; */
    margin-bottom: 12px;
    overflow: auto;
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none;
    position: relative;
  }
  .margin-zero{
    margin: 0 !important;
  }
.contacts-details::-webkit-scrollbar {
    display: none;
}
  .contacts-details > div{
    display: flex;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    line-height: 13px;
  }
  .contacts-details > div > div > i{
    font-size: 32px;
    margin-right: 24px;
    color: #C8C8C8;
  }
  .contacts-details > div > div{
    display: flex;
    align-items: center;
  }
  .contacts-details > div > div:nth-child(1){
    padding-left: 10px;
  }
  .contacts-details > div > div:nth-child(2){
    padding-right: 10px;
  }
  .contacts-details > div > div:nth-child(2) > p{
    /* display: -webkit-box; */
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
  }
  .contacts-details > div > div > div > p:nth-child(1){
    font-size: 1rem;
    font-weight: bold;
  }
  .contacts-details > div > div > div > p:nth-child(2){
    font-size: 1rem;
  }
  .contacts-details > div > div > div > p:nth-child(3){
    font-size: 0.8rem;
    color: #777777;
  }
  .location-icon{
    font-size: 25px;
    color: #C8C8C8;
    margin-right: 15px;
  }
  .hide-location-icon{
    font-size: 25px;
    color: #C8C8C8;
    margin-right: 15px;
    display: none;
  }
  .pick-house{
    color: #ffe201 !important;
  }
