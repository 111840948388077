@keyframes faderIn {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

* {
    animation: .3s faderIn ease-out
}

.trip-deals > div:nth-child(5) > div:nth-child(1) > div > div:nth-child(1){
    width: 49%;
}
.monday-none{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard{
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: #f2f2f2;
    position: relative;
    height: 100vh
}
.moreOptions {
    display: flex;
    flex-direction: column;
}
.options {
    margin-top: 15px;
    border-radius: 50px;
    width: fit-content;
    padding: 0 10px;
    font-size: clamp(12px, 1vw, 18px);
    transition: .0s
}
.options:hover {
    background: rgb(211, 211, 211);
    cursor: pointer;
}
.options1 {
    margin-top: 15px;
    padding-left: 10px;
    font-size: clamp(12px, 1vw, 18px)
}
.theIcon {
    width: 36px;
    padding: 10.5px;
    background: #333;
    border-radius: 50px;
}
.settings-menu {
    position: relative;
    width: 100%;
    margin-top: 20px;
}
.settings-menu > p {
    position: absolute;
    right: 0;
    top: 20px;
    transform: translateY(-10px);
}
.dashboard > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}
.myLinks > div:nth-child(1) {
    height: 30%;
    display: flex;
    justify-content: left;
    align-items: center;
}
@media screen and (max-height: 620px){
    .myLinks > div:nth-child(1) {
        height: 100px;
        min-height: unset;
    }
}
.myLinks > div:nth-child(2) {
    height: 100%;
    position: relative;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 20px;
}

.myLinks > div:nth-child(3) {
    height: 107px;
    position: relative;
}

.zipiicon {
    width: 80px;
}

.dashboard > div:nth-child(2) {
    height: 100%;
    overflow-y: auto;
}
.MuiCircularProgress-root{
    width: 22px !important;
    height: 22px !important;
}
.css-1rn30mb-MuiCircularProgress-root,
.MuiCircularProgress-circleDeterminate,
.css-oxts8u-MuiCircularProgress-circle{
    color: #2cdd00 !important;
}
.account-menu{
    padding-left: 15px;
    font-weight: normal !important;
    overflow: hidden;
    height: 0;
}
.account-menu-open{
    margin-left: 15px;
    font-weight: normal !important;
    overflow: hidden;
    height: auto!important;
}
.arrow-chev{
    position: absolute;
    right: 0;
    top: 10%;
}
.account-menu li a, .account-menu-open li a{
    font-weight: normal !important;
}
.dashboard > div:nth-child(1){
    width: 19%;
    background: #fff;
}

.dashboard > div:nth-child(2){
    width: 81%;
}
.logo-section{
    width: 100%;
    height: 40%;
    display: flex;
}
.zipilogo{
    display: block;
    margin-top: 54%;
    max-width: 34%;
    max-height: 9%;
    width: 426px;
    height: 191px;
    object-fit: contain;
}
.myLinks > div{
    padding-left: 50px;
    padding-right: 50px;
}
.dash-link-icon{
    margin-right: 10px;
    font-size: 13px;
    color: #333333;
}

.right-top-section{
    width: 100%;
    height: 339px;
    background: #333333;
    padding: 68px 68px 0px 68px;
    color: #fff;
}
.stat-card{
    position: relative;
    width: 100%;
    margin-top: 8px;

}
.stat-card > div{
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 0px 68px;
    width: 100%;
    transform: translateY(-50%);
}
.stat-card > div > div{
    width: 22%;
    background: #fff;
    border-radius: 13px;
    display: flex;
    padding: 16px 23px;
    flex-direction: column;
    position: relative;
    height: 116px;
}
.bids-i-p{
    color: #fff !important;
    background: #000;
    width: 43%;
    padding: 5px 6px;
    border-radius: 13px;
    font-size: 11px;
    position: absolute;
    top: 87%;
    right: 26%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.dash-mid-values{
    height: 32px;
    display: flex;
    align-items: center;
}
.bids-i-p > i{
    margin-right: 2px !important;
    color: #fff !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}
.stat-ico{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.stat-ico > img{
    width: 26px;
    height: 26px;
    display: block;
}
.dash-title{
    font-size: 32px;
    font-weight: bold;
}

.dash-top-links{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dash-act-links{
    display: flex;
    justify-content: flex-end;
    width: 60%;
}

.dash-act-links > li{
    margin-right:7%;
}
.dash-subtitle{
    text-transform: uppercase;
    margin-top: 38px;
    font-size: 17px;
}
.book-load{
    padding: 9px 29px;
    background: #fce303;
    color: black;
    border-radius: 24px;
    font-weight: bold;
    font-size: 1.1rem;
    cursor: pointer;
}
.dash-act-links > li > a{
    font-size: 15px;
}
.filter-select{
    margin-top: 14px;
    background: #222222;
    padding: 5px 43px 5px 20px;
    border-radius: 22px;
    width:fit-content;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    appearance: none;
    font-size: 1.3rem;
    cursor: pointer;
}

.side-links li{
    font-size: 11.5px;
    font-weight: bold;
}
.dash-select-arrow{
    color: #fff;
    position: absolute;
    top: 31%;
    right: 16%;
    font-size: 15px;
    pointer-events: none;
}
.active{
    background: #fce200;
    padding: 6px 17px 6px 6px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    align-items: center;
    width: fit-content;
    height: 30px;
    margin-bottom: 8px;
    font-size: 14px !important;
}
.active > i{
    font-size: 18px !important;
}
.side-links{
    line-height: 42px;
    font-size: 14px;
}
.dash-date-title{
    margin-top: 21px;
}
.dash-date-title > h2{
    font-size: 11px;
    font-weight: bold;
}
.dash-date{
    margin-top: 5%;
    padding: 13px 68px;
    overflow: scroll;
}
.dash-date::-webkit-scrollbar{
    display: none;
}
.booking-dash{
    width: 100%;
    background: #fff;
    /* margin-top: 11px; */
    height: 121px;
    max-height: 121px;
    overflow: scroll;
}
.right-top-section > p{
    font-size: 1rem;
}
.book-name{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 22px;
    width: 100%;
    height: 40px;
    border-bottom: 1.5px solid #eaeaea;
}
.book-name p{
    font-size: 11.5px;
}
.left-book-name{
    display: flex;
    align-items: center;
    text-align: center;
}
.right-dash-name{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.right-dash-name > p{
    font-size: 11.6px;
}
.dash-setting{
    width: 100%;
    height: 36%;
    display: flex;
    align-items: end;
    padding-bottom: 30px;
}
.side-menu-section{
    max-height: 24%;
    height: 24%;
    transition: var(--transition);
}
.close-ex{
    width: 30px;
    height: 30px;
    display: block;
    cursor: pointer;
    visibility: hidden;
}
.dash-setting-icon{
    width: 33px;
    height: 33px;
    padding: 7px;
    border-radius: 100%;
    background: #333333;
}
.dash-setting-icon > img{
    width: 100%;
    height: 100%;
    display: block;
}
.dash-icon-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.mobile-setting{
    display: none !important;
}
.dash-bars, .dash-cross{
    display: none !important;
    color: #000;
}
.drop-down-dash-anc{
    display: block;
    position: relative;
    cursor: pointer;
}

.fleet-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: #f2f2f2;
    position: relative;
    flex-direction: column;
}
.start-before{
    position: relative;
}
.start-before::before{
    content: "";
    position: absolute;
    left: -1.6%;
    right: 0;
    top: 0;
    bottom: 0;
    width: 0.5rem;
    height: 0.5rem;
    border: 2px solid black;
    background: #fff;
}
.filling-before, .destination-before{
    position: relative;
}
.filling-before::before{
    content: "";
    position: absolute;
    left: -1.6%;
    right: 0;
    top: 0;
    bottom: 0;
    width: 0.5rem;
    height: 0.5rem;
    border: 2px solid black;
    background: #fff;
    border-radius: 100%;
}
.destination-before::before{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: -1.7%;
    bottom: 0;
    width: 1px;
    height: 6px;
    background: transparent;
    border-right: solid 6px transparent;
    border-left: solid 6px transparent;
    border-top: solid 6px black;
}
.my-referals{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100vh;
}
.fleet-container > p, .my-referals > p{
    font-size: 19px;
    font-weight: bold;
}
.datePickerStyle{
    width:100%;
}
.datePickerStyle input{
    width: 100%;
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-size: 14px;
    margin-bottom: 0.4rem;
}

@media screen and (max-width:768px){
    .dash-bars{
        display: block !important;
        font-size: 37px;
    }
    .loads-offered{
        flex-direction: column !important;
    }
    .loads-offered > div:nth-child(2) > div > input{
        width: 116px;
    }
    .trip-deals > div:nth-child(5) > div > div > div:nth-child(3){
        width: 100% !important;
    }
    .accept-offer{
        margin-top: 10px;
        width: 100% !important;
    }
    .close-ex{
        width: 30px;
        height: 30px;
        display: block;
        cursor: pointer;
        visibility: visible;
    }
    .myLinks > div:nth-child(1){
        padding: 25px !important;
        display: flex;
        justify-content: space-between;
    }
    /* .myLinks > div:nth-child(2){
        height: 0;
    } */
    .side-menu-section{
        height: 0;
        overflow: hidden;
        padding: 0 25px !important;
        transition: var(--transition);
    }
    .dashboard{
        flex-direction: column;
    }
    .dashboard > div:nth-child(1){
        width: 100% !important;
        height: fit-content !important;
    }
    .dash-setting{
        display: none;
    }
    .mobile-setting{
        display: flex !important;
    }
    .dash-version{
        display: none ;
    }
    .logo-section{
        align-items: center;
        height: 136px;
        justify-content: space-between;
    }
    .zipilogo{
        margin-top: 0;
        max-width: 16%;
        max-height: 40%;
        width: 426px;
        height: 191px;
        display: block;
    }
    .side-links li{
        font-size: 15px;
        line-height: 55px;
    }
    .side-links li a{
        line-height: 23px;
    }
    .active{
        height: fit-content;
        font-size: 17px !important;
    }
    .active > i {
        font-size: 21px !important;
    }
    .dash-link-icon {
        font-size: 19px;
    }
    .dashboard > div:nth-child(2){
        width: 100%!important;
    }
    .stat-card > div{
        transform: unset;
        position: unset;
        flex-wrap: wrap;
        padding: 6% 25px;
    }
    .stat-card > div > div{
        width: 48%;
        height: 131px;
        margin-top: 21px;
        justify-content: center;
    }
   .stat-card > div > div > p:nth-child(2){
        font-size: 29px !important;
        margin: 20px 0;
        margin-top: 20px !important;
    }
    .stat-ico > img{
        width: 32px;
        height: 32px;
    }
    .stat-card > div > div > div > p{
        font-size: 17.5px;
    }
    .stat-card > div > div > p{
        font-size: 16px !important;
    }
    .bids-i-p{
        top: 92%;
        padding: 7px 6px;
        border-radius: 18px;
    }
    .dash-title{
        font-size: 35px;
    }
    .dash-subtitle{
        font-size: 16px;
    }
    .right-top-section > p{
        font-size: 12px;
    }
    .filter-select{
        font-size: 16px;
        margin-top: 20px !important;
        height: 57px !important;
    }
    .right-top-section > div:nth-child(4){
        width: 26%;
    }
    .right-top-section{
        padding: 25px !important;
        height: unset !important;
    }
    .dash-act-links > li > a {
        font-size: 19px !important;
    }
    .dash-date-title > h2{
        font-size: 17px !important;
    }
    .left-book-name > h2{
        font-size: 18px!important;
    }
   .left-book-name > p{
        font-size: 15px !important;
   }
   .book-name{
        height: 56px;
   }
    .right-dash-name > div > p{
        font-size: 15px;
    }
    .dash-date{
        margin-top: 0% !important;
        padding: 13px 25px;
    }
}
@media screen and (max-width:531px){
    .dash-bars {
        display: block !important;
        font-size: 31px;
    }
    .zipilogo{
        margin-top: 0;
        max-width: 24% !important;
        max-height: 29%;
        width: 426px;
        height: 191px;
    }
    .dash-top-links{
        flex-wrap: wrap;
    }
    .right-top-section{
        padding: 25px !important;
    }
    .dash-act-links{
        width: 100% !important;
        margin-top: 18px !important;
        justify-content: start !important;
    }
    .dash-act-links > li > a{
        font-size: 13px !important;
    }
    .right-top-section > div:nth-child(4) {
        width: 50%;
    }
    .right-top-section > p {
        line-height: 19px;
    }
    .stat-card > div > div{
        width: 100% !important;
        height: 164px !important;
    }
    .stat-card > div{
        padding: 0px 26px;
    }
    .dash-date{
        padding: 13px 26px;
    }
    .book-name {
        height: fit-content;
        flex-direction: column;
        align-items: start;
        text-align: start !important;
        padding: 10px 22px;
    }
    .right-dash-name{
        flex-direction: column !important;
        width: 100%;
    }
    .right-dash-name > div{
        width: 100%!important;
    }
    .right-dash-name > div > p{
        text-align: start !important;
        line-height: 27px;
    }
    .dash-date-title {
        margin-top: 44px;
    }
    .bids-i-p{
        top: 90%;
    }
    .side-links li {
        font-size: 15px;
        line-height: 55px;
    }
}
@media screen and (max-width: 1300px) {
    .myLinks > div{
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: unset !important;
    }
}
@media screen and (max-width:1024px){


    .bids-i-p{
        width: 51% !important;
        font-size: 10px !important;
    }
    .stat-card > div > div > p{
        font-size: 10px !important;
    }
    .stat-card > div > div > div.dash-mid-values > p{
        font-size: 19px;
    }
    .zipilogoo{
        height: 8% !important;
    }
}
@media screen and (max-width:846px){
    .active{
        padding: 6px 14px 6px 6px !important;
    }
    .bids-i-p{
        width: 63% !important;
    }
}
@media screen and (max-width:1440px){
    .dash-select-container{
        width: 16%;
    }
}