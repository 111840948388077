body{
  background: #f2f2f2;
}
.app {
  background: #f2f2f2;
}
.app::-webkit-scrollbar{
  display: none;
}
.hiddenP{
  white-space: nowrap;      /* Prevents text from wrapping */
  overflow: hidden;         /* Hides the overflowing text */
  text-overflow: ellipsis;  /* Shows ellipsis (...) for overflow */
  width: 48px;
}
/* *{
  transition: all 500ms ease-in-out
} */

/* input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
} */

input[type="radio"], input[type="checkbox"]{
  margin-right: 10px;
}

.css-4l7j15,
.css-1brzq0m{
  min-width: 100% !important;
  width: 100% !important;
  border-radius: 10px !important;
}
.css-j7qwjs{
  border-radius: 10px !important;
}
.css-1v994a0{
  font-size: .8rem !important;
}

label{
  margin-right: 10px;
}

/* input[type="time"]{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
} */

/* Global styles */
h1, h2, h3, h4{
  font-size: 14px;
  padding: 0;
  margin: 3px 0;
}
p{
  margin: 0;
  padding: 0;
  font-size: 12.5px;
  margin-bottom: 3px;
  line-height: 16px;
}

html {
  box-sizing: border-box !important;
  scroll-behavior: smooth;
}

*, *:before, *:after {
  box-sizing: inherit;
}

input:focus, textarea:focus, select:focus {
  outline-width: 0;
}

/* .custom-icon {
  position: absolute;
  top: 40%;
  left: 50%;
  margin-left: 115px;

  border-radius: 50%;
  border: 8px solid green;
  width: 8px;
  height: 8px;
}

.custom-icon::after {
  position: absolute;
  content: '';
  width: 0px;
  height: 0px;
  bottom: -30px;
  left: -6px;
  border: 10px solid transparent;
  border-top: 17px solid green;
} */

.custom-icon{
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #89849b;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s
}
.leaflet-popup-tip-container,
.leaflet-popup-close-button{
  display: none !important;
}
.leaflet-popup{
  margin-bottom: 0px !important;
  border: 1px solid rgb(187, 187, 187);
  border-radius: 12px;
}
.custom-icon::after{
    content: '';
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    background: #2F2F2F;
    position: absolute;
    border-radius: 50%
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.css-dplwbx-MuiPickersCalendarHeader-label {
  margin-right: 6px;
  font-size: .8rem !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width:424px){
  .assign-panel-mobile > div > div{
    width: 100% !important;
  }
}

@media screen and (max-width:768px){
  .side-section{
    display: none !important;
  }
  .new-deals-page{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100% !important;
    max-height: 100% !important;
  }
  .driver-mobile-assign{
    margin-bottom: 1rem !important;
  }
  .overview-modal-mobile{
    height: 100% !important;
  }
  .drivers-mobile{
    width: 100% !important;
    height: 33% !important;
  }
  .drivers-details-mobile{
    width: 100% !important;
    display: flex;
    flex-direction: column;
    height: unset !important;
    width: unset !important;
  }
  .deals-mobile{
    height: 250px !important;
  }
  .trailer-mobile{
    height: 390px !important;
  }
  .assign-mobile{
    display: flex;
    flex-direction: column;
    height: 100% !important;
    max-height: 100% !important;
  }
  .assign-panel-mobile{
    padding: 0rem !important;
    flex-wrap: wrap !important;
    flex-direction: column !important;
    height: auto !important;
  }
  .assign-panel-mobile > div{
    flex-wrap: wrap !important;
  }
  .assign-mobile-first-div{
    width: 100% !important;
    height: 600px !important;
  }
  .assigned-drivers-mobile{
    width: 90% !important;
    padding: unset !important;
    margin: 0 0 1.5rem 0 !important;
  }
  .select-assign-mobile{
    display: flex !important;
    flex-direction: column;
  }
  .instructions-select-mob,
  .select-title-container-mob,
  .select-subtitle-mob{
    width: 100% !important;
  }
  .select-title-container-mob,
  .select-subtitle-mob{
    padding: 0 2rem !important;
  }
  .driver-pan-mob{
    width: 90% !important;
    margin: 0 0 2rem 0 !important;
  }
  .select-icon-mob{
    display: none !important;
  }
  .vehicle-mobile{
    width: 100% !important;
    height: 370px !important;
  }
  .trailer-mobile,
  .trailer-details-mobile{
    width: 100% !important;
  }
  .trailer-details-mobile{
    display: flex;
    flex-direction: column;
  }
  /* .vehicles-details-mobile > div:nth-child(1) > div > div:nth-child(4) > div{
    width: 100% !important;
  } */
  .vehicles-details-mobile{
    width: 100% !important;
    display: flex;
    flex-direction: column;
  }
  .overview-modal-mobile > div > div:nth-child(2) > div > div{
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
  }
  .overview-modal-mobile > div > div:nth-child(2) > div > div > div:nth-child(1),
  .overview-modal-mobile > div > div:nth-child(2) > div > div > div
  .drivers-mobile{
    width: 100% !important;
  }
  #root > div > div > div > div > div.new-deals-page.flex.justify-between.relative.scrollbar-hide > div.deals-mobile.rounded-lg.p-2.scrollbar-hide > div.w-full.overview-modal-mobile.absolute.left-0.right-0.bottom-0 > div > div:nth-child(2) > div.flex.justify-between.over-container-mobile > div.rounded-xl.p-4.scrollbar-hide{
    height: 200px !important;
    max-height: 200px !important;
    overflow: unset !important;
  }
  .overview-mobile{
    position: relative !important;
    margin-top: 1.4rem;
  }
  .over-container-mobile{
    display: flex !important;
    flex-direction: column !important;
  }
  .dealbook-mobile{
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
  }
  .new-deals-page > div > div{
    width: 100% !important;
  }
  .deals-mobile{
    width: 100% !important;
  }
  .new-deals > div{
    width: 100% !important;
    margin-bottom: 1rem !important;
  }
  .body-fleet{
    height: 100% !important;
    margin-top: 1rem;
  }
  .new-deals{
    flex-direction: column !important;
    margin-top: 0 !important;
  }
  .offer-container-last > div:nth-child(2){
    flex-direction: column !important;
  }
  .settings-menu{
    margin-bottom: 10px;
  }
  .dashboard > div:nth-child(1){
    overflow-y: unset !important;
  }
  .myLinks > div{
    padding-bottom: 0px;
  }
  .nav{
    height: 100px !important;
  }
  .enter-wrapper{
    margin-top: 25px !important;
  }
  .dropdown-wrapper{
    top: 74% !important;
  }
  .dashboard > div:nth-child(1){
    background: #e3e3e3 !important;
  }
  .bid-parent{
    margin-top: 10px;
  }
  .btn-settle-block > div{
    margin: 10px 0;
  }
  .bidding-page-container > div:last-child, .actual-bid-block{
    width: 100% !important;
  }
  .accept-the-bid{
    width: 95% !important;
  }
  .bidding-page-container > div:first-child, .btn-settle-block{
    width: 100% !important;
  }
  .bidding-page-container, .bid-parent, .btn-settle-block{
    flex-direction: column !important;
  }
  .animate-addcontact-one{
    width: 93% !important;
  }
  .cargo-modal-wrap{
    flex-direction: column !important;
    width: 83%!important;
  }
  .login-form{
    width: 100% !important;
  }
  .cargo-modal, .left-cargo-secti{
    width: 100%!important;
  }
  .left-cargo-secti{
    height: 220px !important;
    overflow: scroll !important;
  }
  .operating-hours > div > div{
    width: calc(100%) !important;
  }
  .add-contacts-wrapper > div:nth-child(2){
    padding-left: unset !important;
  }
  .cancel-add-btn{
    justify-content: space-between !important;
  }
  .enterprise-booking-page{
    width: 100% !important;
    max-width: unset !important;
    padding: 0 15px;
  }
  .bidding-page-container > div:first-child{
    max-height: 371px;
    min-height: 371px;
  }
  .nav > div{
    width: 51% !important;
  }
  .summary-wrap > div:nth-child(2) > div:nth-child(1){
    height: 76px !important;
  }
  .search,
  .search-container,
  .search-wrapper,
  .pill-container{
    width: 100% !important;
  }
  .search-wrapper{
    width:95% !important;
  }
  .search-container > button{
    width: 45px !important;
    margin-left: 10px;
    height: 45px !important;
  }
  .box-icon > span > i{
    margin-left: unset !important;
  }
  .pill-container > div:nth-child(2) {
    width: 48% !important;
  }
  .pill-container > div:nth-child(3) {
    width: 32% !important;
  }
  .distribution-vehicle > div {
    width: 100% !important;
    flex-wrap: wrap;
    margin-bottom: 11px;
  }
  .distribution-vehicle{
    flex-wrap: wrap;
  }
  .distribution-vehicle > div > div > img {
    width: fit-content !important;
  }
  .profile{
    display: none !important;
  }
  .fa-bars{
    display: block !important;
    color: #fff;
    font-size: 19px;
  }
  .fa-xmark{
    color: #fff;
    font-size: 19px;
  }
  .nav{
    align-items: center !important;
    width: 93% !important;
  }
  .mobile-dropdown-show{
    visibility: visible !important;
    opacity: 1 !important;
    right: 0 !important;
    display: block !important;
    height: fit-content !important;
  }
  .modal, .modal-summary{
    width: 93% !important;
  }
  .summary-wrapper > div:nth-child(1) > div, .summary-wrapper > div:nth-child(2) > div{
    height: unset !important;
  }
  .continue-btn-container{
    width: 91% !important;
    justify-content: space-between !important;
  }
  .other{
    margin-top: 20px ;
  }
  .summary-wrapper{
    flex-wrap: wrap;
  }
  .summary-wrapper > div:nth-child(1){
    display: none !important;
  }
  .schedule-sum, .schedule-sum{
    flex-wrap: wrap !important;
  }
  .schedule-page, .add-contacts-wrapper > div > input, .text-area-wrap > textarea{
    width: 100% !important;
    margin: auto;
    padding: 8px 20px !important;
    max-width: 100% !important;
  }
  .add-contacts-wrapper > div > select{
    max-width: 100% !important;
    width: 100% !important;
  }
  .schedule-sum > div:nth-child(1) > div:nth-child(2) > div{
    margin-left: 12px !important;
  }
  .schedule-sum > div:nth-child(1) > div:nth-child(2) > div:nth-child(2)::before{
    left: -2.5% !important;
  }
  .schedule-sum > div:nth-child(1) > div:nth-child(2) > div:nth-child(3)::before,
  .schedule-sum > div:nth-child(1) > div:nth-child(2) > div:nth-child(4)::before,
  .schedule-sum > div:nth-child(1) > div:nth-child(2) > div:nth-child(5)::before{
    left: -1.5% !important;
  }
  .schedule-sum > div:nth-child(1) > div:nth-child(2), .schedule-sum > div:nth-child(1){
    width: 100% !important;
  }
  .schedule-sum > div:nth-child(1) > div:nth-child(1) > div > p, .schedule-sum > div:nth-child(1) > div:nth-child(1){
    display: none !important;
  }
  .schedule-sum > div:nth-child(2){
    width: 100% !important;
  }
  .cargo-sum-sched > div:not(.cargo-sum-sched > div:nth-child(1)), .vehicle > div,
  .hazardous-wrapper{
    flex-direction: column !important;
  }
  .tracking-child{
    flex-direction: column-reverse !important;
  }
  .quantity-plus-info, .hazardous-second-child, .tracking-child > div:nth-child(1){
    width: 94% !important;
  }
  .quantity-plus-info:nth-child(2) > div:last-of-type, .quantity-plus-info:nth-child(2) > div:first-of-type,
  .hazardous-second-child > div, .temperature-controlled > div{
    text-align: start !important;
    margin-top: 10px;
  }
  .tracking, .bidding, .deals > div:nth-child(2){
    width: 95% !important;
    max-width: 95% !important;
  }
  .load-shipping{
    flex-wrap: wrap;
  }
  .load-shipping > div, .tracking-child > div:nth-child(2){
    width: 100% !important;
    margin-bottom: 10px;
  }
  /* .print-block{
    flex-direction: column;
  } */
  .tracking-child > div.left-tracking-info > div.print-block > span > span > p{
    font-size: 12.5px !important;
  }
  .deals,  .loads-offered{
    flex-wrap: wrap !important;
  }
  .deals > div.trip-deals > div.offer-indication > div:nth-child(1){
    flex-direction: column !important;
  }
  .trip-deals > div.offer-indication > div:nth-child(1) > div{
    margin-bottom: 10px;
  }
  .deals > div.trip-deals > div.offer-indication > div:nth-child(1){
    align-items: start;
  }
  .deals > div.trip-deals > div:nth-child(5) > div > div > div > div > div > div:nth-child(2) > div{
    margin:10px 0;
  }
  .add-contacts{
    width: 100% !important;
  }
  .enterprise-booking-page > div > div > div > img{
    width: 21% !important;
  }
  .add-contacts-wrapper{
    width: 100% !important;
    flex-direction: column;
  }
  .add-contacts-wrapper > div:nth-child(1){
    width: 100% !important;
  }
  .btn-address-type, .double-inputs > input, .deactivate{
    padding: 8px 20px !important;
    width: 100% !important;
    margin-bottom: 10px;
  }
  .add-contacts-wrapper > div:nth-child(1) > div.address-type > label:nth-child(2){
    margin-bottom: unset !important;
  }
  .add-to-contact > button{
    width: 100% !important;
    height: 32px !important;
  }
  .modal-container{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal{
    position: unset !important;
    top: unset !important;
    left: unset !important;
    transform: unset !important;
  }
  .cargo-modal > div > input, .cargo-modal > div > select{
    width: 48.8% !important;
  }
  .cargo-modal > select{
    width: 100% !important;
  }
  .cargo-modal > div.un-number > input[type=text], .upload-file-label{
    margin-bottom: 10px;
    width: 100% !important;
  }
  .un-number{
    flex-direction: column;
  }
  .cargo-modal > div:nth-child(7) > select{
    width: 48% !important;
  }
  .modal-summary{
    padding-bottom: 15px !important;
  }
  .summary-wrapper > div:nth-child(2) > div:nth-child(5) > div > label,
 .summary-wrapper > div:nth-child(2) > div:nth-child(5) > div > label{
    margin-bottom: 10px;
  }
  .address-type, .double-inputs{
    width: 99% !important;
    margin: auto;
    flex-wrap: wrap !important;
  }
  /* .text-area-wrap > textarea{
    margin-left: 4px;
  } */
  .cancel-add-btn{
    width: 100% !important;
  }
  .contacts-details > div > div:nth-child(1){
    padding-left: unset;
  }
  .contacts-details > div > div > i{
    margin-right: 10px !important;
  }
  .location-icon{
    margin-right: 4px !important;
  }
  .change-contact{
    font-size: 9px !important;
    padding: 6px 5px !important;
  }
  .max-min{
    width: 54% !important;
  }
  .deals > div:nth-child(1){
    width: 100% !important;
  }
  .operating-hours{
    padding-left: 6px !important;
    width: 90% !important;
  }
  .time-pill{
    width: 84%;
  }
  .alert-container{
    height: 150% !important;
  }
  .add-contacts-wrapper > div:nth-child(1) > div:nth-child(16), .add-contacts-wrapper > div:nth-child(1) > select{
    margin-top: unset !important;
  }
  .schedule-sum > div:nth-child(1) > div:nth-child(2) > div:nth-child(2),
  .schedule-sum > div:nth-child(1) > div:nth-child(2) > div:nth-child(4){
    display: none !important;
  }
  .search-container > button > img {
    width: 104% !important;
}
}
@media screen and (max-width:1024px){
  .pill-container>div:nth-child(3) {
    width: 40% !important;
  }
  .cargo-quantity{
    width: 67% !important;
  }
  .pill-container{
    width: 68% !important;
  }
}
@media screen and (max-width:425px){
  .pill-container {
    width: 100% !important;
  }
  .change-contact {
    width: 21% !important;
  }
}
@media screen and (max-width:1366px){
  .pill-container > div:nth-child(3) {
    width: 28% !important;
    display: flex;
    justify-content: end;
  }
}
@media screen and (max-width: 375px) {
  .animate-addcontact-one{
    margin-top: 46px !important;
  }
  .cargo-modal-wrap{
    margin-top: 183% !important;  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}


 /* delivery agent to fleet owners */

 /* react calendar css */
 .css-169iwlq-MuiCalendarPicker-root, .css-epd502, .css-1vcokmn-MuiCalendarOrClockPicker-root, .css-u0soqy-MuiPickerStaticWrapper-root{
  width: 100% !important;
 }.css-xelq0e-MuiPickerStaticWrapper-content{
  min-width: 100% !important;
  width: 100% !important;
 }
 .css-15i50ny-MuiButtonBase-root-MuiPickersDay-root,.css-1rm2qbf{
  background-color: #A8A8A8 !important;
 }
 .css-xelq0e-MuiPickerStaticWrapper-content{
  background: #dadada !important;
  border-radius: 10px;
 }
 .css-1brzq0m{
  background: rgb(242, 242, 242) !important;
 }
 .css-15i50ny-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled,
 .css-1rm2qbf.Mui-disabled{
  background: unset !important;
 }
 .css-1186y7m-MuiButtonBase-root-MuiPickersDay-root:hover, .css-1186y7m-MuiButtonBase-root-MuiPickersDay-root:focus,
 .css-1m07dw6-MuiButtonBase-root-MuiPickersDay-root:hover, .css-1m07dw6-MuiButtonBase-root-MuiPickersDay-root:focus{
  background-color: #fce200 !important;
 }
 .css-1186y7m-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected),
 .css-p5pu0f:not(.Mui-selected){
  border: unset !important;
 }
 /* .css-169iwlq-MuiCalendarPicker-root{
  padding: 10px;
 } */
 .css-15i50ny-MuiButtonBase-root-MuiPickersDay-root,
 .css-1rm2qbf.Mui-disabled{
  width: 33px !important;
  height: 33px !important;

 }
