@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Calibri', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Calibri', 'Courier New',
    monospace;
}
img{
  max-width: unset !important;
}
/* p{
  font-size: 0.8rem !important;
} */
