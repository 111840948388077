.tracking{
    width: 100%;
    margin: auto;
    position: relative;
}
.tracking-child{
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: auto;
}
.tracking > h1{
    font-weight: normal;
    padding: 0 6%;
    font-size: 1rem;
}
.tracking-child > div:nth-child(1){
    width: 70%;
    /* border:1px solid; */
}
.tracking-child > div:nth-child(2){
    width: 30%;
    /* border:1px solid; */
}
.tracking-child > div:nth-child(2) > h1{
    font-weight: normal;
    padding-left: 10px;
    font-size: 1rem;
}
.bound-btns{
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
    margin-top: 10px;
}
.bound-btns > button{
    padding: 6px 13px;
    width: 125px;
    border-radius: 10px;
    border: transparent;
    background: #E1E1E1;
    font-size: 14px;
}
.tracking-pannel{
    margin-top: 15px;
    background: #fff;
    padding: 12px;
    border-radius: 10px;
    overflow: scroll;
    max-height: 365px;
}
.tracking-pannel > h1{
    font-weight: normal;
    font-size: 1rem;
}
.search-panel{
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.search-panel > input{
    padding: 11px 10px;
    width: 100%;
    border-radius: 10px;
    border: transparent;
    background: #e1e1e1;
    font-size: 1rem
}
.search-panel > div{
    padding: 11px 10px;
    background: #e1e1e1;
    width: 10%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search-panel > div > img{
    width: 23px;
}
.driver-profile{
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.driver-profile > div:nth-child(1){
    display: flex;
    align-items: center;
}
.driver-profile > div:nth-child(1) > div:nth-child(2){
    margin-left: 10px;
}
.driver-profile > div:nth-child(1) > div:nth-child(2)>h1,
.driver-profile > div:nth-child(1) > div:nth-child(2)>p{
    font-size: 12px;
    line-height: 12px;
}
.progress-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.progress-bars{
    width: 100%;
    margin-top: 10px;
}

.left-tracking-info{
    padding:12px;
}
.load-shipping{
    display: flex;
    justify-content: space-between;
}
.load-shipping > div{
    width: calc(100%/3 - 1.5%);
    background: #fff;
    /* border: 1px solid; */
    border-radius: 10px;
    padding: 10px;
}
.load-shipping > div > p{
    word-break: break-all;
    white-space: normal;
    font-size: 1rem;
}
.cargo-cargo{
    display: flex;
    justify-content: space-between;
}
.cargo-cargo > div > p, .cargo-cargo > div > div > div > p{
    font-size: 1rem;
}
.cargo-cargo > div{
    margin-top: 14px;
    width: 100%;
    /* height: 226px; */
    background: #fff;
    border-radius: 10px;
    padding: 12px;
}
.btn-download{
    margin-top: 10px;
    padding: 0px 0px 0px 21px;
    border: transparent;
    border-radius: 10px;
    background: #707070;
    color: #fff;
    height: 29px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 150px;
    text-decoration: none;
}
.print-block > span > span > p, .print-block > div > span:nth-child(2) > p{
    font-size: 1rem;
}
.btn-download > span{
    padding: 0 10px;
    background: #333333;
    height: 100%;
    margin-left: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 10px 10px 0px;
}
.print-block{
    margin-top: 14px;
    background: #fff;
    border-radius: 10px;
    padding: 12px;
}
/* .tracking-child > div:nth-child(1) p, .tracking-child > div:nth-child(1) h1{
    font-size: 10px;
} */
.package-type-container{
    display:flex;
    justify-content: space-between;
}
.package-type-container > div{
    width: 50%;
}
.marcedes-container{
    display: flex;
    justify-content: space-between;
}
.estimated-time{
    display: flex;
    justify-content: space-between;
}
.print-block > span{
    display: flex;
    align-items: center;
}
.print-block{
    display: flex;
    justify-content: space-between;
}
.get-pdf-btn{
    padding: 10px;
    border-radius: 100%;
    background: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    margin-right: 10px;
}
.print-block > span > img{
    margin-right: 10px;
}
.chat-ico{
    margin-left: 29px;
    padding: 15px;
    background: #333333;
    width: 10px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
}
.print-block > div > span.chat-ico > img{
    display: block;
    max-width: unset;
}

.pulsate{
    animation: custom-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes custom-ping {
    0% {
        right: 50%;
        left: 50%;
        transform: translateX(-50%, -50%);
        transform: scale(0.75);
        opacity: 1;
    }
    75%, 100% {
        right: 50%;
        left: 50%;
        transform: translateX(-50%, -50%);
        transform: scale(1);
        opacity: 0;
    }
}

.driver-container{
    cursor: pointer;
}
.nav-tracking{
    display: flex;
    align-items: center;
    padding: 1rem 3rem
    /* justify-content: center; */
}
.bidding-navigation{
    display: flex;
    font-weight: bold;
}
.bidding-navigation > p{
    font-size: 1rem;
}
.nav-nav-link{
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    background: white;
    color: #000;
    border-radius: 100%;
}
.driver-tracking-bar-container{
    cursor: pointer;
    padding: 0px 10px 10px 10px;
}
.this-booking{
    padding: 10px;
    border: 2px solid #e7e7e7;
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: pointer;
}
.this-booking > div > h2{
    font-weight: normal;
    font-size: 1rem;
}
.this-booking > div.book-populate > p{
    font-size: .8rem;
}
.arrow-block{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    background: #e5e5e5;
    margin-top: 11px;
    border-radius: 0.8rem;
    background: #ffe200;
}
.arrow-block > i{
    font-size: 13px;
    color: #000;
}
.book-populate{
    cursor: pointer;
}
.pdf-container{
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 14px;
    padding-bottom: 15px;
}
.pdf-child{
    width: fit-content;
    padding: 15px;
    background-color: #fff;
    position: relative;
}
.btn-down-pdf{
    padding: 8px;
    border-radius: 5px;
    background: #333333;
    border: transparent;
    color: white;
}

