.nav-container{
    width: 100%;
    background: #333333;
    height: 71px;
    top: 0;
    z-index: 999;
    right: 0;
    height: 74px !important;
}
.nav{
    width: 90%;
    max-width: 90%;
    margin: auto;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;
    position: relative;
}
.nav > div{
    width: 23%;
}
.logo{
    display: flex;
    justify-content: start;
    align-items: center;
    height: 100%;
}
.profile{
    display: flex;
    justify-content: end;
    align-items: center;
    width: fit-content !important;
    position: relative;
    height: 100%;
    cursor: pointer;
    text-align: end;
}
.profile:hover .dropdown-wrapper{
    height: fit-content;
    transition: var(--transition);
    z-index: 99;
}
.profile > p{
    color: #fff;
    margin-right: 10px;
    width: fit-content;
    white-space: nowrap;
    font-size: 1.4rem;
}
.profile > i{
    margin-left: 7px;
    color: #fff;
    font-size: 13px;
}
.add-contacts-wrapper > div:nth-child(1) > p:nth-child(2){
    font-size: 1rem;
}
.add-contacts-wrapper > div:nth-child(1) > {
    font-size: 1.3rem;
}
.dropdown-wrapper{
    position: absolute;
    bottom: 0;
    width: 200px;
    top: 100%;
    border-bottom: 2px solid #fff;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0;
    padding: 0;
    background: #333333;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: start;
    overflow: hidden;
    height: 0;
    transition: var(--transition);
    z-index: 999;
}
.dropdown-wrapper > li{
    list-style: none;
    padding: 11px;
    border-bottom: 1px solid #fff;
    width: 100%;
}
.nav-nav{
    font-style: normal;
    text-decoration: none;
    width: 93%;
    color: #fff;
    display: inline-block;
    text-align: start;
    font-size: 15px;
}
.nav-awe{
    margin-right: 10px;
}
.dropdown-wrapper > li > a >i{
    font-size: 17px;
    color: grey;
    margin-right: 10px;
}
.logo > p{
    margin: 0;
    padding: 0;
    margin-left: 10px;
    margin-top: 20px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
}
