.alert-container{
    display: flex;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 50%;
    top: 50%;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -50%);
}
.alert{
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: min-content;   
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
}