.calendar{
    width: 100%;
    border:none !important;
}
.highlight {
    font-weight: 900;
    background-color: rgb(117, 91, 235);
 }
.loads-input::-webkit-outer-spin-button,
.loads-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.loads-input[type=number] {
  -moz-appearance: textfield;
}