.map-container{
    width: 100%;
    height: 100%;
    border-radius: 15px 0px 0px 15px;
    position:relative;
}
.places-container{
    position: absolute;
    left: 15rem;
    right: 0;
    bottom: 0;
    top:0;
    /* z-index: 999; */
}
.combobox-input{
    padding: 0.4rem 0.75rem;
    margin-bottom: 0.75rem;
    width: 100%;
    background: rgb(214, 214, 214);
    border-radius: 0.75rem;
}

.combobox-input:focus{
    outline: none;
}
.directions{
    position:absolute;
    background: black;
    top: 1rem;
    right: 1rem;
    width: 25%;
    padding: 1rem;
    z-index: 999;
}